import React from 'react';
import {
  CodeSnippet,
  IconDataTableCode,
  PlatformTab,
  Section,
} from '../../../../components';

const AppleTab = ({ data }) => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{}}
        code="let confirmIcon = Icons.permissions.restricted()"
        platform="apple"
        gitHubLink="Icons"
        disableCodeEditing
      />

      <Section title="Icons">
        <IconDataTableCode data={data} platform="apple" />
      </Section>
    </PlatformTab>
  );
};

export default AppleTab;
